import { init } from '@jill64/sentry-sveltekit-cloudflare/client';
import { PUBLIC_SENTRY_DSN, PUBLIC_API_BASE_URL, PUBLIC_COOKIE_DOMAIN } from '$env/static/public';
import { setApiBaseUrl, setProductionCookieDomain } from '@luxfit/backend-api';

setApiBaseUrl(PUBLIC_API_BASE_URL);
setProductionCookieDomain(PUBLIC_COOKIE_DOMAIN);

const onError = init(PUBLIC_SENTRY_DSN, {
    sentryOptions: {}
});

export const handleError = onError();
